<template>
  <div @click="onCardClick">
    <vs-card actionable :class="'cardx ' + object.cardType">
      <div slot="header">
        <el-tooltip
          class="item"
          effect="light"
          :content="data.header"
          placement="right"
        >
             <p
            class="text-lg font-medium"
            :class="{ 'compact-text': /^\d*$/.test(data.header) }"
          >
            {{ data.header }}
          </p>
        </el-tooltip>
      </div>

      <div>
        <p class="text-base" v-if="(data.body || {}).p1">{{ data.body.p1 }}</p>
        <p class="text-sm" v-if="(data.body || {}).p2">{{ data.body.p2 }}</p>
        <p class="text-xs" v-if="(data.body || {}).p3">{{ data.body.p3 }}</p>
        <template v-if="(data.body || {}).metas">
          <p
            class="text-sm break-word"
            v-for="meta in data.body.metas"
            :key="meta.name"
          >
            <b>{{ meta.description }}:</b>&nbsp;{{ meta.value }}
          </p>
        </template>
      </div>

      <div slot="footer">
        <p
          v-if="(data.footer || {}).hideFooterDetailedButton || false == false"
        >
          &nbsp;
        </p>
        <vs-row
          vs-justify="flex-end"
          v-if="!(data.footer || {}).hideFooterDetailedButton && false == true"
        >
          <vs-button
            icon="visibility"
            @click="onCardVisibilityButtonClick"
          ></vs-button>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
export default {
  props: {
    stepName: { type: String },
    object: {
      type: Object
    },
    data: {
      type: Object,
      default: () => ({
        header: "WIZ Soluções Corretora de Seguros S/A",
        body: {
          //p1:"Paragrafo 1",
          //p2:"Paragrafo 2",
          //p3:"Paragrafo 3",
          metas: [
            {
              name: "BrokerQSA1",
              description: "Sócio/Adm",
              value: "Kelvin Cleto Gonçalves"
            },
            {
              name: "BrokerQSA2",
              description: "Sócio/Adm",
              value: "Jefferson Quiles"
            },
            { name: "BrokerQSA3", description: "Sócio/Adm", value: "Mario" }
          ]
        },
        footer: {
          hideFooterDetailedButton: true
        }
      })
    }
  },
  methods: {
    onCardVisibilityButtonClick() {
      this.$emit("onCardViewButtonClick", this.object);
    },
    onCardClick() {
      this.$emit("onCardClick", { ...this.object, stepName: this.stepName });
    }
  }
};
</script>

<style lang="scss" scoped>
.ENDOSSO_ALTERACAO {
  background: rgb(196 224 249)!important;
}
.ENDOSSO_CANCELAMENTO {
  background: rgb(255 229 229) !important;
}
.break-word {
  word-break: break-word;
}

.compact-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
