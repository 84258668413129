<template>
  <div class="dados-gerais p-4 pt-2">
    <vs-tabs color="warning">
      <vs-tab label="Dados Solicitacao">
        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row" v-if="!Newe">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da solicitação</p>
              <p class="text-lg"> {{ data.startDate | moment("DD/MM/YYYY HH:mm") }}</p>
            </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ</p>
              <the-mask v-if="data.broker.Cnpj"
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="data.broker.Cnpj"
              />
              <p v-else class="text-lg"> Não informado</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Usuário solicitante</p>
              <p class="text-lg">{{ data.broker.usuario || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ Tomador</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="data.policyHolder.Cnpj"
              />
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador</p>
              <p class="text-lg">{{ data.policyHolder.Name || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta" v-if="data.broker">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <p class="text-sm font-semibold">Corretor</p>
              <p class="text-lg">{{ data.broker.Corretora || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/3" v-if="data.broker.Cnpj">
              <p class="text-sm font-semibold">CNPJ Corretor</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="data.broker.Cnpj"
              />
            </div>
            <div class="vx-col w-1/3">
              <p class="text-sm font-semibold">Usuário</p>
              <p class="text-lg">{{ data.broker.usuario || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row md:mt-5">
            <div class="vx-col md:w-12/12 w-full md:mt-0 mt-5">
              <p class="text-sm font-semibold">Comentário</p>
              <vs-textarea v-model="data.comment" :disabled="!hasPermission" />
            </div>
          </div>
        </vs-card>

        <div class="vx-row mt-5">
          <div class="vx-col md:w-6/12 w-full md:mt-0 mt-5">
            <router-link
              :to="{
                name: 'detalhe-tomador',
                params: { tomadorUniqueId: tomador.policyHolder.UniqueId },
              }"
              target="_blank"
            >
              <vs-button color="primary" type="flat">
                <span class="font-semibold">
                  Visualizar cadastro completo
                </span>
              </vs-button>
            </router-link>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Parecer">
        <div class="vx-row md:mt-6">
          <vs-collapse
            class="vx-col md:w-12/12 w-full md:mt-0 mt-5"
            type="margin"
            v-for="(observation, index) in observations"
            :key="index"
          >
            <vs-collapse-item>
              <div slot="header">
                <div class="vx-row">
                  <div class="vx-col">
                    <span class="font-semibold"
                      >Responsável: {{ observation.UserName }}</span
                    >
                  </div>
                  <div class="vx-col">
                    <span class="font-semibold"
                      >Data de cadastro:
                      {{
                        observation.CreateDate | moment("DD/MM/YYYY HH:mm")
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <p class="vx-row flex px-8" v-html="observation.Observation"></p>
            </vs-collapse-item>
          </vs-collapse>

          <div class="vx-col md:w-12/12 w-full md:mt-0 mt-5">
            <!-- <ckeditor    v-model="parecer"  :read-only="!hasPermission" ></ckeditor> -->
            <jodit-editor v-model="parecer" read-only="true" />
            <div
              class="pt-4 mt-5 flex flex-row-reverse w-full"
              v-if="hasPermission"
            >
              <vs-button @click="saveParecer(true)">Salvar parecer</vs-button>
            </div>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Documentos">
        <div>
          <div>
            <vs-card
              class="mt-1 p-2"
              v-for="(documento, index) in listaDocumentos"
              :key="index"
            >
              <div class="vx-row flex">
                <div class="vx-col flex-1">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">{{ documento.Name || "Não informado" }}</p>
                  <p class="text-sm pt-4">
                    <b>Status </b>{{ documento.StatusDescription }}
                  </p>
                  <p class="text-sm pt-4">
                    <b>Total de anexos </b>{{ documento.TotalArchives }}
                  </p>
                  <p class="text-sm">
                      <b>Solicitado em </b
                      >{{ documento.CreateDate | moment("DD/MM/YYYY") }}
                    </p>
                </div>
                <div class="vx-col">
                  <div class="text-right w-full flex items-center">
                    <button
                      class="onpoint-download-simple download-button "
                      v-if="documento.TotalArchives > 0"
                      @click="downloadDocumento(documento)"
                    ></button>
                    <vs-button
                      class="delete"
                      v-if="documento.StatusId == 3 && hasPermission"
                      @click="alterarStatusDocumento(documento, 1)"
                      color="success"
                      type="flat"
                      size="large"
                      icon="thumb_up"
                    ></vs-button>
                    <vs-button
                      class="delete"
                      v-if="documento.StatusId == 3 && hasPermission"
                      @click="alterarStatusDocumento(documento, 4)"
                      color="warning"
                      type="flat"
                      size="large"
                      icon="thumb_down"
                    ></vs-button>

                    <!-- <button
                      class="onpoint-trash trash-button"
                      @click="deletarDocumento(documento)"
                    ></button> -->
                  </div>
                </div>
              </div>
            </vs-card>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup
      class="popup-lista-documentos"
      title="Solicitar documentação"
      :active.sync="popupListaDocumentos"
    >
      <vs-tabs color="warning">
        <vs-tab label="Outros documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div
                class="vx-row"
                v-if="(documentosManuaisParaSolicitar || []).length == 0"
              >
                <div class="vx-col w-full mt-2">
                  <vs-alert color="warning" class="h-auto">
                    Nenhum documento adicionado. Clique no botão de adicionar
                    documentos;
                  </vs-alert>
                </div>
              </div>
              <div
                class="vx-row"
                v-for="(
                  documentoManual, index
                ) in documentosManuaisParaSolicitar || []"
                :key="index"
              >
                <div class="vx-col md:w-10/12 w-full mt-5 md:pr-2">
                  <vs-input
                    label-placeholder="Documento"
                    size="large"
                    v-model="documentoManual.Name"
                    class="w-full"
                  />
                </div>
                <div
                  class="vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                >
                  <vs-button
                    class="delete mt-4 ml-2"
                    @click="removeDocumentoManual(index)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="delete"
                  ></vs-button>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    icon-after
                    @click="adicionaDocumentoManual()"
                    class="font-semibold mt-2 float-right"
                    color="primary"
                    type="flat"
                    icon="add_circle"
                  >
                    Adicionar novo Documento
                  </vs-button>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col pt-5 text-right w-full mt-2">
                  <vs-button
                    @click.native="solicitarDocumentosManuais()"
                    color="primary"
                  >
                    <span class="font-semibold pr-2 pl-2">
                      Solicitar documentação
                    </span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>

    <vs-popup
      class="popup-download-arquivos"
      title="Anexos do documento"
      :active.sync="popupListaArquivos"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <h5>{{ (documentoSelecionado || {}).Name }}</h5>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col pt-5 w-full mt-2">
          <vs-card
            class="mt-1 p-2"
            v-for="(archive, index) in arquivosParaDownload"
            :key="index"
          >
            <div class="vx-row flex">
              <div class="vx-col flex-1">
                <p class="text-sm font-semibold"></p>
                <p class="text-lg">
                  {{ archive.OriginalFilename || "Não informado" }}
                  {{ archive.Extension }}
                </p>
                <p class="text-sm">
                  <b>Anexado em </b
                  >{{ archive.CreateDate | moment("DD/MM/YYYY") }}
                </p>
              </div>
              <div class="vx-col">
                <div class="text-right w-full flex items-center">
                  <vs-button
                    class="delete"
                    @click="baixarArquivo(archive)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="get_app"
                  ></vs-button>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { kanbanProvider } from "../../../providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import { Jodit, JoditEditor } from "jodit-vue";
import environment from "../../../environment";

export default {
  name: "RequestLimitDataViewContent",
  directives: { mask },
  components: { VueEditor, TheMask },
  props: {
    data: {
      type: Object,
    },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      listaDocumentos: [],
      uploadDocName: "",
      listaDocumentosDetalhes: [],
      modalListarDocumentos: false,
      popupListaDocumentos: false,
      documentosManuaisParaSolicitar: [],
      financeiro: {},
      responsibleTypeSelected: 0,
      filtroAtivo: 0,
      parecer: "",
      focusEditor: false,
      observations: [],
      perfilHelpers: PerfilHelpers,
      Newe: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
      popupListaArquivos: false,
      documentoSelecionado: null,
      arquivosParaDownload: [],
    };
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },
    tomador: {
      get() {
        return this.data || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      },
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    }
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));

    await this.carregarDocumentos(this.tomador);
    this.carregaParecer();

    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  },
  created() {
    this.setupEditor();
  },
  methods: {
    ...mapActions("documentos-corretora", [
      "obterDocumentosRequestLimit",
      "getDocumentoPorId",
      "getUrlFileDownload",
    ]),
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto",
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        18: "18",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90",
      };
    },
    carregaParecer() {
      this.observations = this.tomador.observations;
    },
    async carregarDocumentos(tomador) {
      await this.$onpoint.loading(async() => {
        return await this.obterDocumentosRequestLimit(
          tomador.policyHolder.UniqueId
        ).then((response) => {
          this.listaDocumentos = response;
        });
      });
    },

    adicionaDocumentoManual() {
      if (!this.documentosManuaisParaSolicitar)
        this.documentosManuaisParaSolicitar = [];

      this.documentosManuaisParaSolicitar.push({ Name: "" });
    },
    removeDocumentoManual(index) {
      this.documentosManuaisParaSolicitar.splice(index, 1);
    },

    mostrarListaDeDocumentos() {
      this.popupListaDocumentos = true;
    },

    async downloadDocumento(documento) {
      this.documentoSelecionado = documento;
      this.arquivosParaDownload = [];
      this.popupListaArquivos = false;

      await this.$onpoint.loading(async() => {
        return await kanbanProvider
          .getRequestDocumentArchives(documento.UniqueId)
          .then(data => {
            this.arquivosParaDownload = data;
            if (this.arquivosParaDownload.length == 1) {
              this.baixarArquivo(this.arquivosParaDownload[0]);
            } else {
              this.popupListaArquivos = true;
            }
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async baixarArquivo(arquivo) {
      await this.$onpoint.loading(async () => {
        return await kanbanProvider
          .getArchiveLinkToDownload(arquivo.UniqueId)
          .then(data => {
            window.open(data, "_blank");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    
    saveParecer() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .CreatePolicyHolderRequestLimitObservation({
            Observation: this.parecer,
            RequestLimitId: this.tomador.requestId,
          })
          .then((data) => {
            this.listaDocumentos = data;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    alterarStatusDocumento(documento, status) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .KanbanRequestLimitChangeStatusRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            StatusId: status,
            PolicyHolderUniqueId: this.tomador.policyHolder.UniqueId,
          })
          .then((data) => {
            this.listaDocumentos = data;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    deletarDocumento(documento) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .KanbanBrokerDeleteRequestDocument({
            BrokerRequestDocumentUniqueId: documento.UniqueId,
            BrokerId: this.corretora.Id,
          })
          .then((data) => {
            this.listaDocumentos = data.BrokerRequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    solicitarDocumentosManuais() {
      const lista = [];
      for (
        let x = 0;
        x < (this.documentosManuaisParaSolicitar || []).length;
        x++
      ) {
        if (
          this.documentosManuaisParaSolicitar[x].Name &&
          this.documentosManuaisParaSolicitar[x].Name.length > 0
        )
          lista.push(this.documentosManuaisParaSolicitar[x].Name);
      }

      if ((lista || []).length == 0) {
        this.$onpoint.errorModal("Adicione pelomenos um documento.");
        return;
      }

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanBrokerRequestDocument({
            RequestDocuments: lista,
            BrokerId: this.corretora.Id,
          })
          .then((data) => {
            this.documentosManuaisParaSolicitar = [];

            this.listaDocumentos = data.BrokerRequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
            //location.reload();
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },


    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },
  },
};
</script>
<style lang="scss">
.invisible-input{
  background-color: transparent;
  border: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
}
</style>
