<template>
  <div class="dados-gerais p-4 pt-2">
    <vs-tabs color="warning">
      <vs-tab label="Solicitação">
        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da solicitação</p>
              <p class="text-lg">{{ nomeacaoRequestDate || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador</p>
              <p class="text-lg">{{ nomeacao.PolicyHolderName || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ Tomador</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="nomeacao.PolicyHolderCpfCnpj"
              />
            </div>
          </div>
          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Corretora Solicitante</p>
              <p class="text-lg">{{ nomeacao.BrokerName || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ Corretora</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="nomeacao.BrokerCpfCnpj"
              />
            </div>
          </div>
          <div class="vx-col flex justify-end">
            <vs-button @click.native="baixarCartaDeNomeacao()" color="primary">
              <span class="font-semibold pr-2 pl-2">
                Baixar carta de nomeação
              </span>
            </vs-button>
          </div>
        </vs-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import { mask, TheMask } from "vue-the-mask";
import { kanbanProvider } from "@/providers/kanban.provider";
import environment from "../../../environment";

export default {
  name: "nomeationRequestDataViewContent",
  directives: { mask },
  components: { TheMask },
  data() {
    return {
      Newe: false,
      selectedOwnerCard: null,
      priorityCard: null,
      selectedPriorityId: null,
    };
  },
  props: {
    data: {
      type: Object
    },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    currentPipe: {},
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    policyHolderCreateDate: {
      get() {
        return this.$moment(this.nomeacao.PolicyHolderCreateDate).format(
          "DD/MM/YYYY"
        );
      }
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    nomeacaoRequestDate: {
      get() {
        return this.$moment(this.nomeacao.RequestDate).format("DD/MM/YYYY");
      }
    },
    cartaNomeacaoArquivoUId: {
      get() {
        return this.nomeacao.Files[0].uniqueIdentifier;
      }
    },
    nomeacao: {
      get() {
        return this.data || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    }
  },
  watch: {
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    },
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  },
  methods: {
    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    baixarCartaDeNomeacao() {
      if (!this.cartaNomeacaoArquivoUId) {
        this.$onpoint.errorModal(
          "Referência do arquivo não encontrada para download."
        );
        return;
      }
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getUrlFileDownload(this.cartaNomeacaoArquivoUId)
          .then(response => {
            window.open(response, "_blank");
            this.$onpoint.notification({
              message: "Download da carta de nomeação iniciado com sucesso."
            });
          })
          .catch(ex => {
            this.$onpoint.errorModal();
          });
      });
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    }
  }
};
</script>

<style lang="scss">
.invisible-input{
  background-color: transparent;
  border: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
}
</style>
