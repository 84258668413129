var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 pl-4 pr-4" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Resumo da Proposta" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável"),
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function ($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard",
                          },
                        },
                        _vm._l(_vm.ownerList, function (item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade"),
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function ($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId",
                          },
                        },
                        _vm._l(_vm.cardPriorityList, function (item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.exibirJustificativaMovimentacaoCard
                ? _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v(
                              "\n              Justificativa para movimentação do card "
                            ),
                            _vm.justificativaMovimentacaoCardObrigatoria
                              ? _c("span", [_vm._v("(obrigatório)")])
                              : _vm._e(),
                            _vm._v(":\n            "),
                          ]),
                          _c("vs-textarea", {
                            staticClass: "mt-4",
                            attrs: {
                              disabled:
                                !_vm.podeModificarJustificativaMovimentacaoCard,
                            },
                            model: {
                              value: _vm.justification,
                              callback: function ($$v) {
                                _vm.justification = $$v
                              },
                              expression: "justification",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full text-right" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.podeModificarJustificativaMovimentacaoCard,
                                color: "primary",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.salvarJustificativa()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n                Salvar\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Nroº Proposta"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.Number || "Não informado")),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }),
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data de Cadastro"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.proposta.CreateDate,
                              "DD/MM/YYYY HH:mm"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Corretora"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s((_vm.proposta.Broker || {}).Name)),
                    ]),
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm._f("maskCpfCnpj")(
                            (_vm.proposta.Broker || {}).CpfCnpj
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("vs-card", { staticClass: "p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Tomador"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.proposta.PolicyHolder.Name || "Não informado"
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Cnpj"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCpfCnpj")(
                              _vm.proposta.PolicyHolder.CpfCnpj ||
                                "Não informado"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "detalhe-tomador",
                              params: {
                                tomadorUniqueId:
                                  _vm.proposta.PolicyHolder.UniqueId,
                              },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              "\n                Visualizar cadastro completo do tomador\n              "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-6" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Segurado"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.proposta.Insured.Name || "Não informado") +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Cpf/Cnpj"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCpfCnpj")(
                              _vm.proposta.Insured.CpfCnpj || "Não informado"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              !_vm.proposta.PolicyHolder.EconomicGroupId &&
              _vm.cardType === "GRUPO_ECONOMICO"
                ? _c("vs-card", { staticClass: "mt-4 p-8" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c(
                            "vs-alert",
                            {
                              staticClass: "h-auto",
                              attrs: { color: "warning" },
                            },
                            [
                              _vm._v(
                                "\n              Tomador sem Grupo econômico definido. Digite o documento do\n              grupo econômico e clique em salvar.\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row mt-5" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "##.###.###/####-##"],
                                expression:
                                  "['###.###.###-##', '##.###.###/####-##']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              label: "Documento do Grupo econômico",
                              size: "large",
                            },
                            model: {
                              value: _vm.grupoEconomico.CpfCnpj,
                              callback: function ($$v) {
                                _vm.$set(_vm.grupoEconomico, "CpfCnpj", $$v)
                              },
                              expression: "grupoEconomico.CpfCnpj",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col text-right w-full mt-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.salvarGrupoEconomico()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n                Atualizar Grupo econômico\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("vs-card", { staticClass: "p-8 pt-4 pb-4 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Grupo Principal"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.proposta.ModalityGroup.Name || "Não informado"
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Modalidade Principal"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.proposta.Modality.Name || "Não informado"
                          ) +
                          "\n              "
                      ),
                      _c("b", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.proposta.Modality.IsDeleted
                                ? "(Modalidade Inativada)"
                                : ""
                            )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mt-6" }, [
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Importância Segurada"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCurrency")(
                              _vm.proposta.InsuredAmountValue
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Inicio Vigência"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.proposta.StartDate,
                              "DD/MM/YYYY"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Fim Vigência"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(_vm.proposta.EndDate, "DD/MM/YYYY")
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Prazo"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.DeadlineDays) + " dias"),
                    ]),
                  ]),
                ]),
              ]),
              _vm.proposta.ComplementaryModality.Id
                ? _c(
                    "vs-card",
                    { staticClass: "p-8 pt-4 pb-4 dados-proposta" },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-1/2" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Modalidade Complementar"),
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.proposta.ComplementaryModality.Name ||
                                    "Não informado"
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-row mt-6" }, [
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Importância Segurada"),
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("maskCurrency")(
                                    _vm.proposta.ComplementaryInsuredAmountValue
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Inicio Vigência"),
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.proposta.ComplementaryStartDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Fim Vigência"),
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.proposta.ComplementaryEndDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Prazo"),
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.proposta.ComplementaryDeadlineDays) +
                                " dias\n            "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.proposta.ObjectValue
                ? _c(
                    "vs-card",
                    { staticClass: "p-8 pt-4 pb-4 dados-proposta" },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-1/1" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Objeto"),
                          ]),
                          _c("p", { staticClass: "text-lg mt-4" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.proposta.ObjectValue || "Não informado"
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.proposta.ContractualTerm
                ? _c(
                    "vs-card",
                    { staticClass: "p-8 pt-4 pb-4 dados-proposta" },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-1/1" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Clausulado"),
                          ]),
                          _c("p", {
                            staticClass: "text-lg mt-4",
                            domProps: {
                              innerHTML: _vm._s(_vm.proposta.ContractualTerm),
                            },
                          }),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("vs-card", { staticClass: "p-8 pt-4 pb-4 dados-proposta" }, [
                _c("div", { staticClass: "vx-row mt-6" }, [
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Prêmio"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCurrency")(
                              _vm.proposta.InsurancePremium
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Taxa"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.RiskRate) + "%"),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Valor da Comissão"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCurrency")(_vm.proposta.ComissionValue)
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("% Comissão"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.ComissionPercentage) + "%"),
                    ]),
                  ]),
                ]),
                _vm.cardType === "PROPOSTA"
                  ? _c("div", { staticClass: "vx-row mt-6" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col text-right w-full mt-2" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "resumo-proposta-visao-seguradora",
                                  params: {
                                    propostaUniqueId: this.proposta.UniqueId,
                                    proposalType: "Proposta",
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm.perfilHelpers.checkPermission([
                                _vm.perfilHelpers.menuFuncionalitiesEnum
                                  .GERENCIAR_PROPOSTAS_SEGURADORA,
                                _vm.perfilHelpers.menuFuncionalitiesEnum
                                  .VISUALIZAR_PROPOSTAS_SEGURADORA,
                              ])
                                ? _c(
                                    "vs-button",
                                    { attrs: { color: "primary" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold pr-2 pl-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                `${
                                                  _vm.perfilHelpers.checkPermission(
                                                    [
                                                      _vm.perfilHelpers
                                                        .menuFuncionalitiesEnum
                                                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                                                    ]
                                                  )
                                                    ? "Editar"
                                                    : "Visualizar"
                                                } informações da Proposta`
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.currentPipe.name === "PEP"
                ? _c("TableSocios", {
                    staticClass: "kaban",
                    attrs: {
                      Socios:
                        _vm.cardType === "SEGURADO_QSA_PEP"
                          ? _vm.socios.InsuredPEPQSAs
                          : _vm.socios.PEPQSAs,
                      TypeText: _vm.cardType,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("vs-tab", { attrs: { label: "Histórico do Motor" } }, [
            !_vm.regrasExecutadas || _vm.regrasExecutadas.length == 0
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-2" },
                    [
                      _c(
                        "vs-alert",
                        { staticClass: "h-auto", attrs: { color: "warning" } },
                        [
                          _vm._v(
                            "\n            Nenhuma regra foi executada nessa proposta.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pt-5 w-full mt-2" },
                _vm._l(_vm.regrasExecutadas, function (regra, index) {
                  return _c(
                    "vs-collapse",
                    { key: index, attrs: { type: "margin" } },
                    [
                      _c("vs-collapse-item", [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("p", { staticClass: "text-sm font-semibold" }),
                            _c("p", { staticClass: "text-lg" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    (regra || {}).EngineExecutionType.Name ||
                                      "Não informado"
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _c("p", { staticClass: "text-sm" }, [
                              _c("b", [_vm._v("Executada em ")]),
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    (regra || {}).CreateDate,
                                    "DD/MM/YYYY"
                                  )
                                ) + "\n                "
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "vx-row flex" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col flex-1" },
                            [
                              _c(
                                "vs-table",
                                {
                                  attrs: {
                                    data:
                                      ((regra || {}).RuleEngineResult || {})
                                        .RuleResults || [],
                                    noDataText:
                                      "Nenhum histórico de regras executado.",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ data }) {
                                          return _vm._l(
                                            data,
                                            function (tr, indextr) {
                                              return _c(
                                                "vs-tr",
                                                {
                                                  key: indextr,
                                                  style: _vm.getRowStyle(
                                                    data[indextr]
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: { data: indextr },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                indextr + 1
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data: data[indextr]
                                                          .RuleName,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data[indextr]
                                                                  .RuleName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data: data[indextr]
                                                          .Success,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data[indextr]
                                                                  .Success
                                                                  ? "Sim"
                                                                  : "Não"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data: data[indextr]
                                                          .Messages,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          (
                                                            data[indextr]
                                                              .Messages || []
                                                          ).length == 0
                                                            ? _c("span")
                                                            : _vm._e(),
                                                          (
                                                            data[indextr]
                                                              .Messages || []
                                                          ).length > 0
                                                            ? _c("div", [
                                                                _c(
                                                                  "ul",
                                                                  _vm._l(
                                                                    data[
                                                                      indextr
                                                                    ]
                                                                      .Messages ||
                                                                      [],
                                                                    function (
                                                                      message,
                                                                      indexMsg
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: indexMsg,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                  " +
                                                                              _vm._s(
                                                                                message
                                                                              ) +
                                                                              "\n                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "thead" },
                                    [
                                      _c("vs-th", [_vm._v(" Etapa ")]),
                                      _c("vs-th", [_vm._v(" Regra ")]),
                                      _c("vs-th", [
                                        _vm._v(" Executada com sucesso? "),
                                      ]),
                                      _c("vs-th", [_vm._v(" Mensagem ")]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ]),
          ]),
          !["TOMADOR_QSA_PEP", "SEGURADO_QSA_PEP"].includes(_vm.cardType)
            ? [
                _c("vs-tab", { attrs: { label: "Follow Up" } }, [
                  _vm.observacoes.length == 0
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full mt-2" },
                          [
                            _c(
                              "vs-alert",
                              {
                                staticClass: "h-auto",
                                attrs: { color: "warning" },
                              },
                              [
                                _vm._v(
                                  "\n              Nenhum follow up até o momento.\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "vx-row px-4" },
                    _vm._l(_vm.observacoes, function (mensagem, index) {
                      return _c(
                        "vs-card",
                        {
                          key: index,
                          staticClass: "p-8 pt-4 pb-4 follow-up-card",
                        },
                        [
                          _c("div", { staticClass: "follow-up-card-content" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "follow-up-card-content-observation",
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "flex items-baseline" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-primary mr-2 mb-2" },
                                      [_vm._v(_vm._s(mensagem.CreateByPerson))]
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        "escreveu em\n                    " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(mensagem.CreateDate),
                                              "DD/MM/YYYY"
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("p", { staticClass: "pl-4" }, [
                                  _vm._v(_vm._s(mensagem.Observation)),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "follow-up-card-content-source" },
                              [
                                _c("span", [_vm._v("Origem:")]),
                                _c(
                                  "span",
                                  { staticClass: "observation-source" },
                                  [_c("b", [_vm._v(_vm._s(mensagem.Source))])]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm.hasPermission
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col pt-5 w-full mt-2" },
                          [
                            _c("label", [_vm._v("Comentário")]),
                            _c("vs-textarea", {
                              model: {
                                value: _vm.observation,
                                callback: function ($$v) {
                                  _vm.observation = $$v
                                },
                                expression: "observation",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasPermission
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: {
                                  disabled: (_vm.observation || "").length == 0,
                                  color: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.enviarObservacao()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                Enviar para o corretor\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("vs-tab", { attrs: { label: "Documentos Solicitados" } }, [
                  _vm.documentos.length == 0
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full mt-2" },
                          [
                            _c(
                              "vs-alert",
                              {
                                staticClass: "h-auto",
                                attrs: { color: "warning" },
                              },
                              [
                                _vm._v(
                                  "\n              Nenhum documento solicitado para a corretora.\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c(
                      "div",
                      _vm._l(_vm.documentos, function (documento, index) {
                        return _c(
                          "vs-card",
                          { key: index, staticClass: "mt-1 p-2" },
                          [
                            _c("div", { staticClass: "vx-row flex" }, [
                              _c("div", { staticClass: "vx-col flex-1" }, [
                                _c("p", {
                                  staticClass: "text-sm font-semibold",
                                }),
                                _c("p", { staticClass: "text-lg" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        documento.Name || "Não informado"
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _c("p", { staticClass: "text-sm pt-4" }, [
                                  _c("b", [_vm._v("Status ")]),
                                  _vm._v(
                                    _vm._s(documento.StatusDescription) +
                                      "\n                  "
                                  ),
                                ]),
                                _c("p", { staticClass: "text-sm pt-4" }, [
                                  _c("b", [_vm._v("Total de anexos ")]),
                                  _vm._v(
                                    _vm._s(documento.TotalArchives) +
                                      "\n                  "
                                  ),
                                ]),
                                _c("p", { staticClass: "text-sm" }, [
                                  _c("b", [_vm._v("Solicitado em ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        documento.CreateDate,
                                        "DD/MM/YYYY"
                                      )
                                    ) + "\n                  "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "vx-col" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right w-full flex items-center",
                                  },
                                  [
                                    documento.TotalArchives > 0
                                      ? _c("button", {
                                          staticClass:
                                            "onpoint-download-simple download-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadDocumento(
                                                documento
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    documento.StatusId == 3 && _vm.hasPermission
                                      ? _c("vs-button", {
                                          staticClass: "delete",
                                          attrs: {
                                            color: "success",
                                            type: "flat",
                                            size: "large",
                                            icon: "thumb_up",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.alterarStatusDocumento(
                                                documento,
                                                1
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    documento.StatusId == 3 && _vm.hasPermission
                                      ? _c("vs-button", {
                                          staticClass: "delete",
                                          attrs: {
                                            color: "warning",
                                            type: "flat",
                                            size: "large",
                                            icon: "thumb_down",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.alterarStatusDocumento(
                                                documento,
                                                4
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.hasPermission
                                      ? _c("button", {
                                          staticClass:
                                            "onpoint-trash trash-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletarDocumento(
                                                documento
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ]),
                  _vm.hasPermission
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.mostrarListaDeDocumentos()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                Adicionar documentos\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "ml-5",
                                attrs: {
                                  disabled: !_vm.possuiDocumentacaoPendente,
                                  color: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.enviarParaCorretor(
                                      "Solicitação de documentos para o corretor."
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                Enviar para o corretor\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
          _vm.isMapfre
            ? [
                _vm.podeExibirCoCorretagemMapfre
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Cocorretagem" } },
                      [
                        _c("vs-card", { staticClass: "mt-4 p-8" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c("div", { staticClass: "vx-col w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Status cocorretagem")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.proposta.HasCoBrokers
                                        ? "Possui cocorretagem"
                                        : "Não possui cocorretagem"
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [
                                  _vm._v(
                                    "\n                Código do quadro de distribuição\n              "
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.cocorretorLider
                                        ? _vm.cocorretorLider
                                            .CodigoQuadroDistribuicao
                                        : "-"
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm.possuiCocorretorLiderMapfre
                          ? _c(
                              "div",
                              [
                                _c("vs-card", { staticClass: "mt-4 p-8" }, [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c("div", { staticClass: "vx-col w-1/2" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "text-lg font-semibold",
                                        },
                                        [_vm._v("Cocorretor líder")]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "vx-row mt-8" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vx-col w-1/2",
                                        style: {
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "flex-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-sm font-semibold",
                                          },
                                          [_vm._v("Razão social")]
                                        ),
                                        _c("p", { staticClass: "text-lg" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                (
                                                  _vm.cocorretorLider
                                                    .CoBrokerDetail || {}
                                                ).Name
                                              ) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "vx-col w-1/2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "vx-row w-full",
                                          style: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          },
                                        },
                                        [
                                          _c("div", {}, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-sm font-semibold",
                                              },
                                              [_vm._v("CNPJ")]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "text-lg" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      (
                                                        _vm.cocorretorLider
                                                          .CoBrokerDetail || {}
                                                      ).CpfCnpj
                                                        ? (
                                                            _vm.cocorretorLider
                                                              .CoBrokerDetail ||
                                                            {}
                                                          ).CpfCnpj
                                                        : "XX.XXX.XXX/XXXX-XX"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", {}, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-sm font-semibold",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      % participação na cocorretagem\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "text-lg" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      (
                                                        _vm.cocorretorLider ||
                                                        {}
                                                      ).Rate.toFixed(2)
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.possuiCocorretorMapfre
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.cocorretores,
                                function (cocorretor, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "vs-card",
                                        { staticClass: "mt-4 p-8" },
                                        [
                                          _c("div", { staticClass: "vx-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-1/2" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-lg font-semibold",
                                                  },
                                                  [_vm._v("Cocorretor")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-8" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-1/2",
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("Razão social")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            (
                                                              cocorretor.CoBrokerDetail ||
                                                              {}
                                                            ).Name
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vx-row w-full",
                                                      style: {
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                        alignItems: "center",
                                                      },
                                                    },
                                                    [
                                                      _c("div", {}, [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-sm font-semibold",
                                                          },
                                                          [_vm._v("CNPJ")]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-lg",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  cocorretor
                                                                    .CoBrokerDetail
                                                                    .CpfCnpj
                                                                    ? (
                                                                        cocorretor.CoBrokerDetail ||
                                                                        {}
                                                                      ).CpfCnpj
                                                                    : "XX.XXX.XXX/XXXX-XX"
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("div", {}, [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-sm font-semibold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        % participação na cocorretagem\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-lg",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  (
                                                                    cocorretor ||
                                                                    {}
                                                                  ).Rate.toFixed(
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.cardType === "PROPOSTA"
                          ? _c("div", { staticClass: "vx-row mt-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col text-right w-full mt-2",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "resumo-proposta-visao-seguradora",
                                          params: {
                                            propostaUniqueId:
                                              this.proposta.UniqueId,
                                            proposalType: "Proposta",
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm.perfilHelpers.checkPermission([
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .GERENCIAR_PROPOSTAS_SEGURADORA,
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .VISUALIZAR_PROPOSTAS_SEGURADORA,
                                      ])
                                        ? _c(
                                            "vs-button",
                                            { attrs: { color: "primary" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pr-2 pl-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        `${
                                                          _vm.perfilHelpers.checkPermission(
                                                            [
                                                              _vm.perfilHelpers
                                                                .menuFuncionalitiesEnum
                                                                .GERENCIAR_PROPOSTAS_SEGURADORA,
                                                            ]
                                                          )
                                                            ? "Editar"
                                                            : "Visualizar"
                                                        } informações da Proposta`
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.podeExibirCosseguro
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Cosseguro" } },
                      [
                        _c("vs-card", { staticClass: "mt-4 p-8" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c("div", { staticClass: "vx-col w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Tipo de cosseguro")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.tipoCosseguro(_vm.tipoCosseguradora)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm.tipoCosseguradora == 1
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.cosseguradoras,
                                function (cosseguradora, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "vs-card",
                                        { staticClass: "mt-4 p-8" },
                                        [
                                          _c("div", { staticClass: "vx-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-1/2" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-lg font-semibold",
                                                  },
                                                  [_vm._v("Cosseguradora")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-8" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-2/3",
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("Razão social")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).CoInsurance
                                                                .Person.Name
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/3" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("CNPJ")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "maskCpfCnpj"
                                                            )(
                                                              _vm.tratarCampoVazio(
                                                                (
                                                                  cosseguradora ||
                                                                  {}
                                                                ).CoInsurance
                                                                  .Person
                                                                  .CpfCnpj
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-6" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Código cosseguradora"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).CoInsuranceId
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % de gastos\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).PercentSpanding.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % de participação\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).PercentParticipation.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    comissão de cosseguro\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).Comission.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.tipoCosseguradora == 2
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.cosseguradoras,
                                function (cosseguradora, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "vs-card",
                                        { staticClass: "mt-4 p-8" },
                                        [
                                          _c("div", { staticClass: "vx-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-1/2" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-lg font-semibold",
                                                  },
                                                  [_vm._v("Cosseguradora")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-8" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-2/3",
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("Razão social")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).CoInsurance
                                                                .Person.Name
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/3" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("CNPJ")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "maskCpfCnpj"
                                                            )(
                                                              _vm.tratarCampoVazio(
                                                                (
                                                                  cosseguradora ||
                                                                  {}
                                                                ).CoInsurance
                                                                  .Person
                                                                  .CpfCnpj
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-6" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Código cosseguradora líder"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).CodeCoInsurance
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % de gastos\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).PercentSpanding.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % de participação\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).PercentParticipation.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % de corretor\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).PercentBroker.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-6" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Identificação (opcional)\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).NumberIdentity
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Data da identificação\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              _vm.tratarCampoVazio(
                                                                (
                                                                  cosseguradora ||
                                                                  {}
                                                                ).IdentityDate
                                                              ),
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Número apólice líder\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              ).LeadPolicyNumber
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/4" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Endosso\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.tratarCampoVazio(
                                                              (
                                                                cosseguradora ||
                                                                {}
                                                              )
                                                                .NumberEndorsement
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.cardType === "PROPOSTA"
                          ? _c("div", { staticClass: "vx-row mt-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col text-right w-full mt-2",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "resumo-proposta-visao-seguradora",
                                          params: {
                                            propostaUniqueId:
                                              this.proposta.UniqueId,
                                            proposalType: "Proposta",
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm.perfilHelpers.checkPermission([
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .GERENCIAR_PROPOSTAS_SEGURADORA,
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .VISUALIZAR_PROPOSTAS_SEGURADORA,
                                      ])
                                        ? _c(
                                            "vs-button",
                                            { attrs: { color: "primary" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pr-2 pl-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        `${
                                                          _vm.perfilHelpers.checkPermission(
                                                            [
                                                              _vm.perfilHelpers
                                                                .menuFuncionalitiesEnum
                                                                .GERENCIAR_PROPOSTAS_SEGURADORA,
                                                            ]
                                                          )
                                                            ? "Editar"
                                                            : "Visualizar"
                                                        } informações da Proposta`
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.podeExibirResseguro
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Resseguro" } },
                      [
                        _c("vs-card", { staticClass: "mt-4 p-8" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c("div", { staticClass: "vx-col w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Tipo de resseguro")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.proposta.HasReinsurers
                                        ? "Facultativo"
                                        : "Contrato automático"
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm.proposta.HasReinsurers
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.resseguradoras,
                                function (resseguradora, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "vs-card",
                                        { staticClass: "mt-4 p-8" },
                                        [
                                          _c("div", { staticClass: "vx-row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "vx-col w-1/2" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-lg font-semibold",
                                                  },
                                                  [_vm._v("Resseguradora")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-8" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-col w-1/2",
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("Razão social")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            (
                                                              resseguradora ||
                                                              {}
                                                            ).Reinsurer.Person
                                                              .Name
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/2" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("CNPJ")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "maskCpfCnpj"
                                                            )(
                                                              (
                                                                resseguradora ||
                                                                {}
                                                              ).Reinsurer.Person
                                                                .CpfCnpj
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "vx-row mt-6" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/3" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    % participação do contrato\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            (
                                                              resseguradora ||
                                                              {}
                                                            ).Percent.toFixed(2)
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/3" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [_vm._v("Número slip")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            (
                                                              resseguradora ||
                                                              {}
                                                            ).NumberSlip
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "vx-col w-1/3" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-sm font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Ano contrato resseguradora"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "text-lg" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            (
                                                              resseguradora ||
                                                              {}
                                                            ).ContractYear
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.cardType === "PROPOSTA"
                          ? _c("div", { staticClass: "vx-row mt-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vx-col text-right w-full mt-2",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "resumo-proposta-visao-seguradora",
                                          params: {
                                            propostaUniqueId:
                                              this.proposta.UniqueId,
                                            proposalType: "Proposta",
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm.perfilHelpers.checkPermission([
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .GERENCIAR_PROPOSTAS_SEGURADORA,
                                        _vm.perfilHelpers.menuFuncionalitiesEnum
                                          .VISUALIZAR_PROPOSTAS_SEGURADORA,
                                      ])
                                        ? _c(
                                            "vs-button",
                                            { attrs: { color: "primary" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-semibold pr-2 pl-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        `${
                                                          _vm.perfilHelpers.checkPermission(
                                                            [
                                                              _vm.perfilHelpers
                                                                .menuFuncionalitiesEnum
                                                                .GERENCIAR_PROPOSTAS_SEGURADORA,
                                                            ]
                                                          )
                                                            ? "Editar"
                                                            : "Visualizar"
                                                        } informações da Proposta`
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-lista-documentos",
          attrs: {
            title: "Solicitar documentação",
            active: _vm.popupListaDocumentos,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupListaDocumentos = $event
            },
          },
        },
        [
          _c(
            "vs-tabs",
            { attrs: { color: "warning" } },
            [
              _c("vs-tab", { attrs: { label: "Lista de Documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    (_vm.documentosPadrao || []).length > 0
                      ? _c(
                          "ul",
                          { staticClass: "mt-8" },
                          _vm._l(
                            _vm.documentosPadrao,
                            function (documento, index) {
                              return _c(
                                "li",
                                {
                                  key: documento.Id,
                                  class: { "pt-5": index > 0 },
                                },
                                [
                                  _vm.documentoJaFoiSolicitado(documento.Name)
                                    ? _c(
                                        "vs-checkbox",
                                        {
                                          attrs: { "vs-value": documento.Id },
                                          model: {
                                            value: _vm.documentosParaSolicitar,
                                            callback: function ($$v) {
                                              _vm.documentosParaSolicitar = $$v
                                            },
                                            expression:
                                              "documentosParaSolicitar",
                                          },
                                        },
                                        [_vm._v(_vm._s(documento.Name))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    (_vm.documentosPadrao || []).length == 0
                      ? _c("div", [
                          _c("p", [
                            _vm._v("Nenhum tipo de documento encontrado!"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                (_vm.documentosPadrao || []).length > 0
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.solicitarDocumentos()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n                Solicitar documentação\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("vs-tab", { attrs: { label: "Outros documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      (_vm.documentosManuaisParaSolicitar || []).length == 0
                        ? _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-2" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto",
                                    attrs: { color: "warning" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Nenhum documento adicionado. Clique no botão de adicionar\n                  documentos;\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        _vm.documentosManuaisParaSolicitar || [],
                        function (documentoManual, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "vx-row" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-10/12 w-full mt-5 md:pr-2",
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      "label-placeholder": "Documento",
                                      size: "large",
                                    },
                                    model: {
                                      value: documentoManual.Name,
                                      callback: function ($$v) {
                                        _vm.$set(documentoManual, "Name", $$v)
                                      },
                                      expression: "documentoManual.Name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center",
                                },
                                [
                                  _c("button", {
                                    staticClass:
                                      "onpoint-trash remove-document-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDocumentoManual(index)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "font-semibold mt-2 float-right",
                                attrs: {
                                  "icon-after": "",
                                  color: "primary",
                                  type: "flat",
                                  icon: "add_circle",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.adicionaDocumentoManual()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Adicionar novo Documento\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.solicitarDocumentosManuais()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                    Solicitar documentação\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-download-arquivos",
          attrs: {
            title: "Anexos do documento",
            active: _vm.popupListaArquivos,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupListaArquivos = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v(_vm._s((_vm.documentoSelecionado || {}).Name))]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              _vm._l(_vm.arquivosParaDownload, function (archive, index) {
                return _c("vs-card", { key: index, staticClass: "mt-1 p-2" }, [
                  _c("div", { staticClass: "vx-row flex" }, [
                    _c("div", { staticClass: "vx-col flex-1" }, [
                      _c("p", { staticClass: "text-sm font-semibold" }),
                      _c("p", { staticClass: "text-lg" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              archive.OriginalFilename || "Não informado"
                            ) +
                            "\n                " +
                            _vm._s(archive.Extension) +
                            "\n              "
                        ),
                      ]),
                      _c("p", { staticClass: "text-sm" }, [
                        _c("b", [_vm._v("Anexado em ")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(archive.CreateDate, "DD/MM/YYYY")
                          ) + "\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "vx-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-right w-full flex items-center" },
                        [
                          _c("vs-button", {
                            staticClass: "delete",
                            attrs: {
                              color: "primary",
                              type: "flat",
                              size: "large",
                              icon: "get_app",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.baixarArquivo(archive)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              }),
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }