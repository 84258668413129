<template>
  <div class="filtro-content">
    <vs-button color="primary" type="border" class="max-w-lg" @click="filter = true">
      Prioridades
    </vs-button>
    <vs-popup title="Prioridades" :active.sync="filter" id="filtro-open">
      <slot></slot>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "filtro",
  data() {
    return {
      isMobile: true,
      filter: false
    };
  },
  props: {
    dropdownKey: Number,
    titleName: String,
    isPriorityFilter: Boolean
  },
    watch: {
    isPriorityFilter(newVal, oldVal) {
      this.filter = false;
    }
  },
  methods: {
    checkDevice() {
      if (window.innerWidth > 576) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    log(event) {}
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
  }
};
</script>

<style lang="scss">
#filtro-open {
  .vs-popup {
    min-width: 60%;
    max-width: 90%;
  }
  .vs-popup--title h3 {
    font-weight: 700 !important;
  }

  .container-element {
    position: relative;

    .label-content {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 5px;
    }
    .label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }
  }

  h5.title-group {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    width: 100%;
    padding: 10px 0.5rem 0;
  }
}
</style>
