<template>
  <div class="dados-gerais p-4 pt-2">
    <vs-tabs color="warning">
      <vs-tab label="Tomador">
        <vs-card class="mt-4 p-8 dados-gerais">
          <div class="vx-row" v-if="!Newe">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-gerais">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Razão Social</p>
              <p class="text-lg">{{ tomador.Name || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">CNPJ</p>
              <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="tomador.CpfCnpj"
              />
            </div>

          </div>
          <div class="vx-row mt-4" >
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data do cadastro</p>
              <p class="text-lg">
                {{ new Date(tomador.CreateDate) | dateFormat('DD/MM/YYYY') }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">E-mail</p>
              <p class="text-lg">
                {{ tomador.Email ||'Não informado' }}
              </p>
            </div>
          </div>
        </vs-card>

        <div class="vx-col w-1/2 mt-5">
          <router-link
            :to="{
              name: 'detalhe-tomador',
              params: { tomadorUniqueId: tomador.UniqueId }
            }"
            target="_blank"
          >
            <span class="font-semibold">
              Visualizar cadastro completo do tomador
            </span>
          </router-link>
        </div>

        <vs-divider class="mt-5 mb-5" />

        <template v-if="(representantes || []).length > 0">
          <div class="vx-row">
            <div class="vx-col w-full mt-5">
              <h4>Representantes legais do tomador</h4>
            </div>
          </div>

          <vs-card class="mt-4 p-8 dados-gerais">
            <div class="vx-row"
              v-for="representante in representantes"
              :key="representante.Id"
            >
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">Nome</p>
                <p class="text-lg">{{ representante.Name || "Não informado" }}</p>
              </div>
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">CPF</p>
                <the-mask
                  class="text-lg invisible-input"
                  :mask="['###.###.###-##']"
                  disabled="true"
                  :masked="true"
                  :value="representante.Cpf"
                />
              </div>
              <div
                class="vx-col mt-3 w-1/1"
              >
                <p class="text-sm font-semibold">E-mail</p>
                <p class="text-lg">{{ representante.Email || "Não informado" }}</p>
              </div>
            </div>
          </vs-card>

        </template>

        <template v-if="(fiadoresPF || []).length > 0">
          <vs-divider class="mt-10" />
          <div class="vx-col w-full mt-5">
            <h4>Fiadores do tomador (Pessoas Físicas)</h4>
          </div>
          <vs-card class="mt-4 p-8 dados-gerais">
            <div class="vx-row"
              v-for="fiador in fiadoresPF" :key="fiador.Id"
            >
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">Nome</p>
                <p class="text-lg">{{ fiador.Name || "Não informado" }}</p>
              </div>
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">CPF</p>
                <the-mask
                  class="text-lg invisible-input"
                  :mask="['###.###.###-##']"
                  disabled="true"
                  :masked="true"
                  :value="fiador.Cpf "
                />
              </div>
              <div
                class="vx-col mt-3 w-1/1"
              >
                <p class="text-sm font-semibold">E-mail</p>
                <p class="text-lg">{{ fiador.Email || "Não informado" }}</p>
              </div>
              <vs-divider/>
            </div>
          </vs-card>
        </template>

        <template v-if="(fiadoresPJ || []).length > 0">
          <vs-divider class="mt-10" />
          <div class="vx-col w-full mt-5">
            <h4>Fiadores do tomador (Pessoas Jurídicas)</h4>
          </div>
          <vs-card class="mt-4 p-8 dados-gerais">
            <div class="vx-row"
              v-for="fiador in fiadoresPJ" :key="fiador.Id"
            >
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">Razão Social</p>
                <p class="text-lg">{{ fiador.CompanyName || "Não informado" }}</p>
              </div>
              <div
                class="vx-col mt-3 w-1/2"
              >
                <p class="text-sm font-semibold">CNPJ</p>
                <the-mask
                class="text-lg invisible-input"
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="fiador.CNPJ"
              />
              </div>
              <div
                class="vx-col mt-3 w-1/1"
              >
                <p class="text-sm font-semibold">Nome</p>
                <p class="text-lg">{{ fiador.Name || "Não informado" }}</p>
              </div>
              <div
                class="vx-col mt-3 w-1/1"
              >
                <p class="text-sm font-semibold">E-mail</p>
                <p class="text-lg">{{ fiador.Email || "Não informado" }}</p>
              </div>
              <vs-divider/>
            </div>
          </vs-card>
        </template>

        <div class="vx-row mt-10" v-if="lastArchiveUniqueId">
          <div class="vx-col text-right w-full">
            <vs-button
              @click.native="downloadCCGArchive(lastArchiveUniqueId)"
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Visualizar documento
              </span>
            </vs-button>
          </div>
        </div>

        <div class="vx-row mt-10 pl-3 pr-3">
          <template
            v-if="
              this.ccg.StatusId == $enums.CCGStatus.AguardandoAnaliseSeguradora &&
                hasPermission
            "
          >
            <label>Observação</label>
            <vs-textarea v-model="observation" />
            <div class="mt-5 vx-col text-right w-full pl-0 pr-0">
              <vs-button @click.native="sendToBroker()" color="primary">
                <span class="font-semibold pr-2 pl-2">Enviar para corretor</span>
              </vs-button>
            </div>
          </template>
          <template v-else>
            <label>Observação enviada</label>
            <vs-textarea v-model="this.ccg.Observation" disabled />
          </template>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import { mask, TheMask } from "vue-the-mask";
import { documentProvider } from "../../../providers/document-provider";
import { kanbanProvider } from "../../../providers/kanban.provider";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import environment from "../../../environment";

export default {
  name: "cCGDataViewContent",
  directives: { mask },
  components: { TheMask },
  data() {
    return {
      observation: "",
      perfilHelpers: PerfilHelpers,
      Newe: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
    };
  },
  props: {
    data: {
      type: Object
    },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    currentPipe: { type: Object },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    tomador: {
      get() {
        return this.data.PolicyHolder || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    ccg: {
      get() {
        return this.data.CCG || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    representantes: {
      get() {
        if (this.ccg && this.ccg.Parts && this.ccg.Parts.Representatives)
          return this.ccg.Parts.Representatives.filter(representante => {
            return !representante.IsDeleted;
          });
        else return [];
      }
    },
    fiadoresPJ: {
      get() {
        if (this.ccg && this.ccg.Parts && this.ccg.Parts.Guarantors)
          return this.ccg.Parts.Guarantors.filter(fiador => {
            return !fiador.IsDeleted && fiador.Entity === "J";
          });
        else return [];
      }
    },
    fiadoresPF: {
      get() {
        if (this.ccg && this.ccg.Parts && this.ccg.Parts.Guarantors)
          return this.ccg.Parts.Guarantors.filter(fiador => {
            return !fiador.IsDeleted && fiador.Entity === "F";
          });
        else return [];
      }
    },
    lastArchiveUniqueId: {
      get() {
        return (this.data.ContractArchive || {}).UniqueId;
      }
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    }
  },
  methods: {
    downloadCCGArchive(archiveUniqueId) {
      this.$onpoint.loading(() => {
        return documentProvider
          .getArchiveLinkToDownload(archiveUniqueId)
          .then(data => {
            window.open(data, "_blank");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    sendToBroker() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanPolicyHolderCCGSendToBroker({
            PolicyHolderCCGUniqueId: this.ccg.UniqueId,
            Observation: this.observation
          })
          .then(data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("refreshPipe");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  }
};
</script>

<style lang="scss">
.scroll-area--card-data-view {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  //height: calc(var(--vh, 1vh) * 105 -16px -45px -82px) !important;

  &:not(.ps) {
    overflow-y: auto;
  }
}
.invisible-input{
  background-color: transparent;
  border: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
}
</style>
