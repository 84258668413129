<template>
  <div class="dados-gerais p-4 pt-2">
    <div class="vx-row">
      <vs-card class="mt-4 p-8">
        <div class="vx-row">
          <div class="vx-col w-8/12">
            <p class="text-sm font-semibold">Tomador</p>
            <p class="text-lg">{{ tomador.Name }}</p>
          </div>
          <div class="vx-col w-4/12">
            <p class="text-sm font-semibold">Cnpj</p>
            <p class="text-lg">{{ tomador.CpfCnpj }}</p>
          </div>
        </div>
        <div class="vx-row mb-6 mt-2">
          <div class="vx-col w-1/2">
            <router-link
              :to="{
                name: 'detalhe-tomador',
                params: { tomadorUniqueId: tomador.UniqueId }
              }"
              target="_blank"
            >
              <span class="font-semibold">
                Visualizar cadastro completo do tomador
              </span>
            </router-link>
          </div>
        </div>
        <div class="vx-row pt-5">
          <div class="vx-col w-1/5">
            <p class="text-sm font-semibold">Data de Cadastro</p>
            <p class="text-lg">
              {{ tomador.CreateDate | moment("DD/MM/YYYY") }}
            </p>
          </div>
        </div>
        <!-- <div class="text-right mt-5 px-8 w-full">
                <router-link :to="{name: 'detalhe-tomador', params: { tomadorUniqueId: tomador.UniqueId }}" target="_blank">
                  <vs-button color="primary" type="flat">
                    <span class="font-semibold">
                      Visualizar cadastro completo
                    </span>
                  </vs-button>
                </router-link>
              </div> -->
      </vs-card>
      <vs-card class="mt-4 p-8" v-if="!(tomador || {}).EconomicGroupId">
        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col w-full">
            <vs-alert color="warning" class="h-auto">
              Grupo econômico não definido. Digite o documento do grupo
              econômico e clique em salvar.
            </vs-alert>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-input
              label="Documento do Grupo econômico"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              v-model="grupoEconomico.CpfCnpj"
              size="large"
              class="w-full"
              :disabled="!hasPermission"
            />
          </div>
        </div>
        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col text-right w-full mt-2">
            <vs-button @click.native="salvarGrupoEconomico()" color="primary">
              <span class="font-semibold pr-2 pl-2">
                Salvar Grupo econômico
              </span>
            </vs-button>
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import Datepicker from "vuejs-datepicker";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import { mapGetters } from 'vuex';

export default {
  name: "economicGroupDataViewContent",
  directives: { mask },
  components: { TheMask, Datepicker },
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      grupoEconomico: {},

      perfilHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    tomador: {
      get() {
        return this.data || {};
      }
    }
  },
  methods: {
    salvarGrupoEconomico() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanSaveEconomicGroup({
            PolicyHolderUniqueId: this.tomador.UniqueId,
            CpfCnpjEconomicGroup: this.grupoEconomico.CpfCnpj
          })
          .then(data => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Grupo econômico definido com sucesso.",
              color: "success"
            });
            this.$emit("refreshPipe");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    }
  }
};
</script>
