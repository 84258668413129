var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dados-gerais p-4 pt-2" }, [
    _c(
      "div",
      { staticClass: "vx-row" },
      [
        _c("vs-card", { staticClass: "mt-4 p-8" }, [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-8/12" }, [
              _c("p", { staticClass: "text-sm font-semibold" }, [
                _vm._v("Tomador"),
              ]),
              _c("p", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(_vm.tomador.Name)),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-4/12" }, [
              _c("p", { staticClass: "text-sm font-semibold" }, [
                _vm._v("Cnpj"),
              ]),
              _c("p", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(_vm.tomador.CpfCnpj)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "detalhe-tomador",
                        params: { tomadorUniqueId: _vm.tomador.UniqueId },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v(
                        "\n              Visualizar cadastro completo do tomador\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row pt-5" }, [
            _c("div", { staticClass: "vx-col w-1/5" }, [
              _c("p", { staticClass: "text-sm font-semibold" }, [
                _vm._v("Data de Cadastro"),
              ]),
              _c("p", { staticClass: "text-lg" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("moment")(_vm.tomador.CreateDate, "DD/MM/YYYY")
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
        !(_vm.tomador || {}).EconomicGroupId
          ? _c("vs-card", { staticClass: "mt-4 p-8" }, [
              _vm.hasPermission
                ? _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-alert",
                          {
                            staticClass: "h-auto",
                            attrs: { color: "warning" },
                          },
                          [
                            _vm._v(
                              "\n            Grupo econômico não definido. Digite o documento do grupo\n            econômico e clique em salvar.\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "vx-row mt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression:
                            "['###.###.###-##', '##.###.###/####-##']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "Documento do Grupo econômico",
                        size: "large",
                        disabled: !_vm.hasPermission,
                      },
                      model: {
                        value: _vm.grupoEconomico.CpfCnpj,
                        callback: function ($$v) {
                          _vm.$set(_vm.grupoEconomico, "CpfCnpj", $$v)
                        },
                        expression: "grupoEconomico.CpfCnpj",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.hasPermission
                ? _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col text-right w-full mt-2" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { color: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.salvarGrupoEconomico()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "font-semibold pr-2 pl-2" },
                              [
                                _vm._v(
                                  "\n              Salvar Grupo econômico\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }