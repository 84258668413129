var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dados-gerais p-4 pt-2" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Tomador" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                !_vm.Newe
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2" },
                        [
                          _c(
                            "p",
                            { staticClass: "text-sm font-semibold mb-1" },
                            [_vm._v("Responsável")]
                          ),
                          _c(
                            "vs-select",
                            {
                              on: { change: _vm.atualizarResponsavel },
                              model: {
                                value: _vm.selectedOwnerCard,
                                callback: function ($$v) {
                                  _vm.selectedOwnerCard = $$v
                                },
                                expression: "selectedOwnerCard",
                              },
                            },
                            _vm._l(_vm.ownerList, function (item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item, text: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2" },
                        [
                          _c(
                            "p",
                            { staticClass: "text-sm font-semibold mb-1" },
                            [_vm._v("Prioridade")]
                          ),
                          _c(
                            "vs-select",
                            {
                              on: { change: _vm.atualizarPrioridade },
                              model: {
                                value: _vm.selectedPriorityId,
                                callback: function ($$v) {
                                  _vm.selectedPriorityId = $$v
                                },
                                expression: "selectedPriorityId",
                              },
                            },
                            _vm._l(
                              _vm.cardPriorityList,
                              function (item, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  attrs: { value: item.Id, text: item.Name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Razão Social"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.tomador.Name || "Não informado")),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ"),
                      ]),
                      _c("the-mask", {
                        staticClass: "text-lg invisible-input",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.tomador.CpfCnpj,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-4" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data do cadastro"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              new Date(_vm.tomador.CreateDate),
                              "DD/MM/YYYY"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("E-mail"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.tomador.Email || "Não informado") +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col w-1/2 mt-5" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "detalhe-tomador",
                          params: { tomadorUniqueId: _vm.tomador.UniqueId },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(
                          "\n            Visualizar cadastro completo do tomador\n          "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("vs-divider", { staticClass: "mt-5 mb-5" }),
              (_vm.representantes || []).length > 0
                ? [
                    _c("div", { staticClass: "vx-row" }, [
                      _c("div", { staticClass: "vx-col w-full mt-5" }, [
                        _c("h4", [_vm._v("Representantes legais do tomador")]),
                      ]),
                    ]),
                    _c(
                      "vs-card",
                      { staticClass: "mt-4 p-8 dados-gerais" },
                      _vm._l(_vm.representantes, function (representante) {
                        return _c(
                          "div",
                          { key: representante.Id, staticClass: "vx-row" },
                          [
                            _c("div", { staticClass: "vx-col mt-3 w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Nome")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(representante.Name || "Não informado")
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-col mt-3 w-1/2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("CPF")]
                                ),
                                _c("the-mask", {
                                  staticClass: "text-lg invisible-input",
                                  attrs: {
                                    mask: ["###.###.###-##"],
                                    disabled: "true",
                                    masked: true,
                                    value: representante.Cpf,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "vx-col mt-3 w-1/1" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("E-mail")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(representante.Email || "Não informado")
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              (_vm.fiadoresPF || []).length > 0
                ? [
                    _c("vs-divider", { staticClass: "mt-10" }),
                    _c("div", { staticClass: "vx-col w-full mt-5" }, [
                      _c("h4", [
                        _vm._v("Fiadores do tomador (Pessoas Físicas)"),
                      ]),
                    ]),
                    _c(
                      "vs-card",
                      { staticClass: "mt-4 p-8 dados-gerais" },
                      _vm._l(_vm.fiadoresPF, function (fiador) {
                        return _c(
                          "div",
                          { key: fiador.Id, staticClass: "vx-row" },
                          [
                            _c("div", { staticClass: "vx-col mt-3 w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Nome")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(_vm._s(fiador.Name || "Não informado")),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-col mt-3 w-1/2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("CPF")]
                                ),
                                _c("the-mask", {
                                  staticClass: "text-lg invisible-input",
                                  attrs: {
                                    mask: ["###.###.###-##"],
                                    disabled: "true",
                                    masked: true,
                                    value: fiador.Cpf,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "vx-col mt-3 w-1/1" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("E-mail")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(_vm._s(fiador.Email || "Não informado")),
                              ]),
                            ]),
                            _c("vs-divider"),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              (_vm.fiadoresPJ || []).length > 0
                ? [
                    _c("vs-divider", { staticClass: "mt-10" }),
                    _c("div", { staticClass: "vx-col w-full mt-5" }, [
                      _c("h4", [
                        _vm._v("Fiadores do tomador (Pessoas Jurídicas)"),
                      ]),
                    ]),
                    _c(
                      "vs-card",
                      { staticClass: "mt-4 p-8 dados-gerais" },
                      _vm._l(_vm.fiadoresPJ, function (fiador) {
                        return _c(
                          "div",
                          { key: fiador.Id, staticClass: "vx-row" },
                          [
                            _c("div", { staticClass: "vx-col mt-3 w-1/2" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Razão Social")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(fiador.CompanyName || "Não informado")
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "vx-col mt-3 w-1/2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("CNPJ")]
                                ),
                                _c("the-mask", {
                                  staticClass: "text-lg invisible-input",
                                  attrs: {
                                    mask: ["##.###.###/####-##"],
                                    disabled: "true",
                                    masked: true,
                                    value: fiador.CNPJ,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "vx-col mt-3 w-1/1" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("Nome")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(_vm._s(fiador.Name || "Não informado")),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col mt-3 w-1/1" }, [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("E-mail")]
                              ),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(_vm._s(fiador.Email || "Não informado")),
                              ]),
                            ]),
                            _c("vs-divider"),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              _vm.lastArchiveUniqueId
                ? _c("div", { staticClass: "vx-row mt-10" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col text-right w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { color: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.downloadCCGArchive(
                                  _vm.lastArchiveUniqueId
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "font-semibold pr-2 pl-2" },
                              [
                                _vm._v(
                                  "\n              Visualizar documento\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vx-row mt-10 pl-3 pr-3" },
                [
                  this.ccg.StatusId ==
                    _vm.$enums.CCGStatus.AguardandoAnaliseSeguradora &&
                  _vm.hasPermission
                    ? [
                        _c("label", [_vm._v("Observação")]),
                        _c("vs-textarea", {
                          model: {
                            value: _vm.observation,
                            callback: function ($$v) {
                              _vm.observation = $$v
                            },
                            expression: "observation",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-5 vx-col text-right w-full pl-0 pr-0",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.sendToBroker()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [_vm._v("Enviar para corretor")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c("label", [_vm._v("Observação enviada")]),
                        _c("vs-textarea", {
                          attrs: { disabled: "" },
                          model: {
                            value: this.ccg.Observation,
                            callback: function ($$v) {
                              _vm.$set(this.ccg, "Observation", $$v)
                            },
                            expression: "this.ccg.Observation",
                          },
                        }),
                      ],
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }