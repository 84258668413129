<template>
   <div class="dados-gerais p-4 pt-2">
    <vs-tabs color="warning">
      <vs-tab label="Cadastro">
        <vs-card class="mt-4 p-8">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8">
          <div class="vx-row ">
            <div class="vx-col md:w-6/12 w-full md:mt-0 mt-5">
              <p class="text-sm font-semibold">Data de Cadastro</p>
              <p class="text-lg">
                {{ tomador.CreateDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col w-6/12">
              <p class="text-sm font-semibold">Tomador</p>
              <p class="text-lg">{{ tomador.Name }}</p>
            </div>
            <div class="vx-col w-6/12">
              <p class="text-sm font-semibold">Cnpj</p>
              <p class="text-lg">{{ tomador.CpfCnpj }}</p>
            </div>
          </div>

          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2">
              <router-link
                :to="{
                  name: 'detalhe-tomador',
                  params: { tomadorUniqueId: tomador.UniqueId }
                }"
                target="_blank"
              >
                <span class="font-semibold">
                  Visualizar cadastro completo do tomador
                </span>
              </router-link>
            </div>
          </div>

          <div
            class="vx-row mt-4"
            v-if="(tomador || {}).PersonRisk.BranchActivity"
          >
            <div class="vx-col">
              <p class="text-sm font-semibold">Ramo de atividade</p>
              <p class="text-lg">{{ tomador.PersonRisk.BranchActivity }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="mt-4 p-8"
          v-if="!(tomador || {}).PersonRisk.BranchActivity"
        >
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-alert color="warning" class="h-auto">
                Ramo de atividade não definido. Escolha o ramo de atividade e
                clique em salvar.
              </vs-alert>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <v-select
                label="BranchNameFull"
                class="w-full"
                :show-no-options="false"
                :show-no-results="false"
                :options="branchs"
                v-model="branchActivity"
                :clearable="false"
                placeholder="Selecione uma opção"
                :disabled="!hasPermission"
              >
                <template slot="option" slot-scope="option">
                  <span class="fa"></span>
                  {{ option.Code }} {{ option.Name }}
                </template>
              </v-select>
            </div>
          </div>
          <div class="vx-row" v-if="hasPermission">
            <div class="vx-col text-right w-full mt-2">
              <vs-button @click.native="salvarRamoAtividade()" color="primary">
                <span class="font-semibold pr-2 pl-2">
                  Salvar ramo de atividade
                </span>
              </vs-button>
            </div>
          </div>
        </vs-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import Datepicker from "vuejs-datepicker";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import { mapGetters } from "vuex";
import environment from "../../../environment";

export default {
  name: "branchActivityDataViewContent",
  directives: { mask },
  components: { TheMask, Datepicker },
  props: {
    data: {
      type: Object
    },
    cardId: {
      type: String
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      branchActivity: {},
      branchs: [],
      perfilHelpers: PerfilHelpers,
      Newe: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
    };
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    tomador: {
      get() {
        return this.data || {};
      }
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    }
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    if (!(this.tomador || {}).PersonRisk.BranchActivity) {
      this.getBranchActivities();
    }
    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  },
  methods: {
    salvarRamoAtividade() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanSaveBranchActivity({
            PolicyHolderUniqueId: this.tomador.UniqueId,
            BranchActivity: this.branchActivity
          })
          .then(data => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Ramo de atividade definido com sucesso.",
              color: "success"
            });
            this.$emit("refreshPipe");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    getBranchActivities() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanGetBranchActivities()
          .then(data => {
            this.branchs = data;
            this.branchs.map(function(x) {
              return (x.BranchNameFull = x.Code + " " + x.Name);
            });
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },
  }
};
</script>
