var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filtro-content" },
    [
      _c(
        "vs-button",
        {
          staticClass: "max-w-lg",
          attrs: { color: "primary", type: "border" },
          on: {
            click: function ($event) {
              _vm.filter = true
            },
          },
        },
        [_vm._v("\n    Prioridades\n  ")]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Prioridades",
            active: _vm.filter,
            id: "filtro-open",
          },
          on: {
            "update:active": function ($event) {
              _vm.filter = $event
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }